<template>
  <div>

    <!-- Filter Section -->
    <FilterReport title="Filters" :baseReportUrl="baseReportUrl" @clicked-filter="getReport" />

    <div class="mt-1 mb-1 d-flex justify-content-end">

      <b-button variant="outline-primary" @click="print">
        <feather-icon icon="FileIcon" />
        {{ $t('globalActions.print') }}
      </b-button>

      <b-button-group class="ml-1">
        <b-dropdown right variant="outline-primary">

          <template #button-content>
            <feather-icon icon="DownloadIcon"/>
            {{ $t('globalActions.export') }}
          </template>

          <b-dropdown-item-button @click="exportToPDF">PDF</b-dropdown-item-button>
          <b-dropdown-item-button @click="exportToExcel">Excel</b-dropdown-item-button>

        </b-dropdown>
      </b-button-group>

    </div>

    <!-- Table Container Card -->
    <b-card no-body id="pdfDom">

        <b-card-body class="text-center">
          <h2>{{ companyName }}</h2>
          <h3>{{ $t('apps.reportWorkSheet.singular.workSheetReport') }}</h3>
          <h4 v-if="officeReportText" class="mt-sm-1">{{ officeReportText }}</h4>
          <h4 class="mt-sm-1">{{ periodReportText }}</h4>
        </b-card-body>

        <b-table-simple id="trial-balance-table" responsive>
          <b-thead>
            <b-tr>
              <b-th rowspan="2" class="text-center align-middle" sticky-column style="min-width: 350px;">COA</b-th>
              <b-th colspan="2" class="text-center">Beginning Amount</b-th>
              <b-th colspan="2" class="text-center">Mutation Amount</b-th>
              <b-th colspan="2" class="text-center">Balance Amount</b-th>
              <b-th colspan="2" class="text-center">Profit / Loss Amount</b-th>
              <b-th colspan="2" class="text-center">Balance Sheet</b-th>
            </b-tr>
            <b-tr>
              <b-th class="text-right">Debit</b-th>
              <b-th class="text-right">Credit</b-th>
              <b-th class="text-right">Debit</b-th>
              <b-th class="text-right">Credit</b-th>
              <b-th class="text-right">Debit</b-th>
              <b-th class="text-right">Credit</b-th>
              <b-th class="text-right">Debit</b-th>
              <b-th class="text-right">Credit</b-th>
              <b-th class="text-right">Debit</b-th>
              <b-th class="text-right">Credit</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(prop) in itemLists" :key="prop.coa">
              <!-- <b-td v-for="column in tableColumns" :key="`${column.key}-${index}`" :class="column.tdClass">{{ prop[column.key] }}</b-td> -->
              <b-td sticky-column>{{ prop.coa }}</b-td>
              <b-td class="text-right">{{ prop.beginning_balance_debit_view }}</b-td>
              <b-td class="text-right">{{ prop.beginning_balance_credit_view }}</b-td>
              <b-td class="text-right">{{ prop.mutation_balance_debit_view }}</b-td>
              <b-td class="text-right">{{ prop.mutation_balance_credit_view }}</b-td>
              <b-td class="text-right">{{ prop.ending_balance_debit_view }}</b-td>
              <b-td class="text-right">{{ prop.ending_balance_credit_view }}</b-td>
              <b-td class="text-right">{{ prop.profit_loss_debit_view }}</b-td>
              <b-td class="text-right">{{ prop.profit_loss_credit_view }}</b-td>
              <b-td class="text-right">{{ prop.balance_sheet_debit_view }}</b-td>
              <b-td class="text-right">{{ prop.balance_sheet_credit_view }}</b-td>
            </b-tr>
          </b-tbody>
          <b-tfoot>
            <b-tr>
              <b-td class="text-right font-weight-bolder font-italic" sticky-column>Ikhtisar Laba Rugi</b-td>
              <b-td class="text-right font-weight-bolder font-italic">0</b-td>
              <b-td class="text-right font-weight-bolder font-italic">0</b-td>
              <b-td class="text-right font-weight-bolder font-italic">0</b-td>
              <b-td class="text-right font-weight-bolder font-italic">0</b-td>
              <b-td class="text-right font-weight-bolder font-italic">0</b-td>
              <b-td class="text-right font-weight-bolder font-italic">0</b-td>
              <b-td class="text-right font-weight-bolder font-italic">{{ profitLoss &lt; 0 ? formatCurrency(profitLoss * -1, false) : 0 }}</b-td>
              <b-td class="text-right font-weight-bolder font-italic">{{ profitLoss &gt; 0 ? formatCurrency(profitLoss * 1, false) : 0 }}</b-td>
              <b-td class="text-right font-weight-bolder font-italic">{{ balance &lt; 0 ? formatCurrency(balance * -1, false) : 0 }}</b-td>
              <b-td class="text-right font-weight-bolder font-italic">{{ balance &gt; 0 ? formatCurrency(balance * 1, false) : 0 }}</b-td>
            </b-tr>
            <b-tr>
              <b-td class="text-right font-weight-bolder font-italic" sticky-column>Total</b-td>
              <b-td class="text-right font-weight-bolder font-italic">{{ total.totalBeginningBalanceDebit }}</b-td>
              <b-td class="text-right font-weight-bolder font-italic">{{ total.totalBeginningBalanceCredit }}</b-td>
              <b-td class="text-right font-weight-bolder font-italic">{{ total.totalMutationBalanceDebit }}</b-td>
              <b-td class="text-right font-weight-bolder font-italic">{{ total.totalMutationBalanceCredit }}</b-td>
              <b-td class="text-right font-weight-bolder font-italic">{{ total.totalEndingBalanceDebit }}</b-td>
              <b-td class="text-right font-weight-bolder font-italic">{{ total.totalEndingBalanceCredit }}</b-td>
              <b-td class="text-right font-weight-bolder font-italic">{{ total.totalProfitLossDebit }}</b-td>
              <b-td class="text-right font-weight-bolder font-italic">{{ total.totalProfitLossCredit }}</b-td>
              <b-td class="text-right font-weight-bolder font-italic">{{ total.totalBalanceDebit }}</b-td>
              <b-td class="text-right font-weight-bolder font-italic">{{ total.totalBalanceCredit }}</b-td>
            </b-tr>
          </b-tfoot>
        </b-table-simple>

    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BButtonGroup,
  BButton,
  BDropdown,
  BDropdownItemButton,
  BTableSimple,
  BThead,
  BTh,
  BTr,
  BTd,
  BTbody,
  BTfoot
} from 'bootstrap-vue'
import FilterReport from '@/components/reports/FilterReport.vue'

import { computed, ref } from '@vue/composition-api'
import { formatCurrency } from '@/utils/formatter'
import useListTable from '@/comp-functions/useListTable'
import useFilterReport from '@/comp-functions/useFilterReport'

export default {
  components: {
    BCard,
    BCardBody,
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItemButton,
    // BTable,
    BTableSimple,
    BThead,
    BTh,
    BTr,
    BTd,
    BTbody,
    BTfoot,
    FilterReport
  },
  computed: {
    companyName () {
      return this.$store.state.user.currentCompany.name
    }
  },
  setup () {
    // export format
    const formats = ['xlsx', 'csv', 'txt']
    const profitLoss = ref(0)
    const balance = ref(0)

    // Table Handlers
    const tableColumns = [
      { key: 'coa', label: 'COA', thClass:'', tdClass: '' },
      { key: 'beginning_debit_view', label: 'Debit', thClass:'text-right', tdClass: 'text-right' },
      { key: 'beginning_credit_view', label: 'Credit', thClass:'text-right', tdClass: 'text-right' },
      { key: 'mutation_debit_view', label: 'Debit', thClass:'text-right', tdClass: 'text-right' },
      { key: 'mutation_credit_view', label: 'Credit', thClass:'text-right', tdClass: 'text-right' },
      { key: 'balance_debit_view', label: 'Debit', thClass:'text-right', tdClass: 'text-right' },
      { key: 'balance_credit_view', label: 'Credit', thClass:'text-right', tdClass: 'text-right' },
      // { key: 'balance_view', label: 'Balance', thClass:'text-right', tdClass: 'text-right' }
    ]

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    } = useListTable({ url: false })
    
    const total = computed(() => {
      const totalBeginningBalanceDebit = formatCurrency(itemLists.value.reduce((accumulator, {beginning_balance_debit}) => accumulator + beginning_balance_debit, 0))
      const totalBeginningBalanceCredit = formatCurrency(itemLists.value.reduce((accumulator, {beginning_balance_credit}) => accumulator + beginning_balance_credit, 0))
      const totalMutationBalanceDebit = formatCurrency(itemLists.value.reduce((accumulator, {mutation_balance_debit}) => accumulator + mutation_balance_debit, 0))
      const totalMutationBalanceCredit = formatCurrency(itemLists.value.reduce((accumulator, {mutation_balance_credit}) => accumulator + mutation_balance_credit, 0))
      const totalEndingBalanceDebit = formatCurrency(itemLists.value.reduce((accumulator, {ending_balance_debit}) => accumulator + ending_balance_debit, 0))
      const totalEndingBalanceCredit = formatCurrency(itemLists.value.reduce((accumulator, {ending_balance_credit}) => accumulator + ending_balance_credit, 0))
      let totalProfitLossDebit = itemLists.value.reduce((accumulator, {profit_loss_debit}) => accumulator + profit_loss_debit, 0)
      let totalProfitLossCredit = itemLists.value.reduce((accumulator, {profit_loss_credit}) => accumulator + profit_loss_credit, 0)
      let totalBalanceDebit = itemLists.value.reduce((accumulator, {balance_sheet_debit}) => accumulator + balance_sheet_debit, 0)
      let totalBalanceCredit = itemLists.value.reduce((accumulator, {balance_sheet_credit}) => accumulator + balance_sheet_credit, 0)

      profitLoss.value = totalProfitLossDebit - totalProfitLossCredit
      totalProfitLossDebit = profitLoss.value < 0 ? totalProfitLossDebit + (profitLoss.value * -1) : totalProfitLossDebit
      totalProfitLossCredit = profitLoss.value > 0 ? totalProfitLossCredit + (profitLoss.value) : totalProfitLossCredit
      balance.value = totalBalanceDebit - totalBalanceCredit
      totalBalanceDebit = balance.value < 0 ? totalBalanceDebit + (balance.value * -1) : totalBalanceDebit
      totalBalanceCredit = balance.value > 0 ? totalBalanceCredit + (balance.value ) : totalBalanceCredit
      return { 
        totalBeginningBalanceDebit,
        totalBeginningBalanceCredit ,totalMutationBalanceDebit,
        totalMutationBalanceCredit ,
        totalEndingBalanceDebit,
        totalEndingBalanceCredit,
        totalProfitLossDebit: formatCurrency(totalProfitLossDebit),
        totalProfitLossCredit: formatCurrency(totalProfitLossCredit),
        totalBalanceDebit: formatCurrency(totalBalanceDebit),
        totalBalanceCredit: formatCurrency(totalBalanceCredit) }
    })

    return {
      profitLoss,
      balance,
      total,
      formatCurrency,
      formats,
      tableColumns,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      ...useFilterReport({
        baseUrl: 'reports/work-sheet',
        fetchLists
      })
    }
  },
  methods: {
    print () {
      window.print()
    },
    exportToPDF () {
      this.$nextTick(() => this.getPdf())
    },
    exportToExcel () {
      import('@/vendors/Export2Excel').then(excel => {
        const list = this.itemLists

        // add title
        // const title = [this.$store.state.user.currentCompany.name, this.$t('apps.reportLedger.singular.ledgerReport'), this.periodReportText]
        const headerTitle = this.tableColumns.map(column => column.label)
        // only take indexData from columnDef,
        const headerVal = this.tableColumns.map(column => column.key)
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          multiHeader: [
            [this.$store.state.user.currentCompany.name, '', '', '', '', '', ''],
            ['Ledger Report', '', '', '', '', '', ''],
            [this.periodReportText, '', '', '', '', '', ''],
            ['', '', '', '', '', '', '']
          ],
          header: headerTitle,
          data,
          merges: [
            { s: { c: 0, r: 0 }, e: { c: 6, r: 0 } },
            { s: { c: 0, r: 1 }, e: { c: 6, r: 1 } },
            { s: { c: 0, r: 2 }, e: { c: 6, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 6, r: 3 } }
          ],
          filename: `Ledger Report ${this.periodReportText}`,
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .dropdown-menu .dropdown-item {
    width: 100%;
  }

  @media print {
    #app {
      * {
        visibility: hidden;
        margin: 0 !important;
      }

      #pdfDom,
      #pdfDom * {
        visibility: visible;
      }

      #pdfDom {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        margin-top: 100px;
      }
    }
  }

  @page {
    size: auto;
  }
</style>
